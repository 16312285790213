import React from 'react';
import {Unity, useUnityContext} from "react-unity-webgl";


export function UnityBuild(data) {
    const { unityProvider } = useUnityContext({
        loaderUrl: data.loaderUrl,
        dataUrl: data.dateUrl,
        frameworkUrl: data.frameworkUrl,
        codeUrl: data.codeUrl
    });
    
    return (
        <>
            <Unity unityProvider={unityProvider}/>
        </>
    );
}

